import React from "react"
// import { Redirect } from '@reach/router' 

// const IndexPage = () => <Redirect to={`/vancouver/`} />

// export default IndexPage

import { useStaticQuery, graphql, Link } from "gatsby"
// import Img from "gatsby-image/withIEPolyfill"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ogimage from "../../static/images/ogimage.png"
import coa from "../../static/images/ubc_coat_of_arms.png"

const IndexPage = ( ) => {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(relativePath: {eq: "cloud_landing.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  // ,
  //     coatOfArms: file(relativePath: {eq: "ubc_coat_of_arms.png"}) {
  //       childImageSharp {
  //         fixed(width: 128) {
  //           ...GatsbyImageSharpFixed
  //         }
  //       }
  //     }
  return (
    <Layout>
      <Seo 
        title="UBC Vancouver Graduation: Spring 2021"
        description="The University of British Columbia’s virtual graduation ceremony for spring 2021."
        image={ogimage} />
      <BackgroundImage
        Tag={`section`}
        className={`homepage-hero image-max-width`}
        loading='auto'
        fadeIn={false}
        fluid={[data.headerImage.childImageSharp.fluid, `linear-gradient(rgba(0,33,69,0.69), rgba(0,33,69,0.69))`].reverse()}
      >
        <div className="bound text-center">
          <div className="homepage-padding">
            <h1>Welcome to UBC&rsquo;s virtual graduation ceremonies</h1>
            <div className="campus-selection">
              <p className="h2 text-color-white">Please choose a campus</p>
              <div className="button-container">
                <Link
                  to="/vancouver/"
                  className=""
                  >
                  <button>Vancouver</button>
                </Link>
                <a 
                  href="https://virtualgraduation.ok.ubc.ca/"
                  className=""
                  >
                  <button>Okanagan</button>
                </a>
              </div>
            </div>
            <div className="coat-of-arms">
              <img src={coa} alt="UBC Coat of Arms" width="128" />
              {/* <Img 
                fixed={data.coatOfArms.childImageSharp.fixed}
                alt="UBC Coat of Arms" />  */}
            </div>
          </div>
        </div>
      </BackgroundImage>
    </Layout>
  )
}

export default IndexPage